<template>
  <div class="ct">
    <ul>
      <li>
        <router-link to="/rootPassword" tag="div">修改母码</router-link>
      </li>
      <li>
        <router-link to="/fingerprint" tag="div">指纹管理</router-link>
      </li>
      <li>
        <router-link to="/password" tag="div">密码管理</router-link>
      </li>
      <li>
        <router-link to="/card" tag="div">卡管理</router-link>
      </li>
    </ul>
    </div>
</template>

<script>

export default {
    name: 'indexPage',
    data() {
        return {}
    },
    computed: {},
    methods: {},
    mounted() {

    }
}
</script>

